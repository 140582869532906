import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import Icon from '@svgs/logo_odawara_icon.svg'

const LandplanPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainLandplan'>
      {/* CONTENTS */}

      <ComMain
        ttl="LAND PLAN"
        subttl="ランドプラン"
        caption="自主管理広場完成予想イラスト"
      >
        <StaticImage
          src="../assets/images/landplan/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/landplan/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>

      <section className='sec-landplan'>


        <div className="layout-wrap">
          <div className="txt-box comFeed">
            <div className="layout-txt-box">
              <p className="ttl"><span><Icon /></span>LAND PLAN</p>
              <p className="txt">「ル・サンク小田原栄町」は敷地の2方が接道する、「銀座通り交差点」角地に立地。独立性のある開放感の高い敷地に全邸南向きの住戸計画とし、駐車場や自転車置場にはゲートを設け、セキュリティ面にも十分に配慮しています。</p>
            </div>
          </div>

          <div className="img-box">
            <div className="img layout comFeed">
              <StaticImage
                src="../assets/images/landplan/layout.png"
                alt=""
              />
              <p className='c-cap black'>敷地配置完成予想イラスト</p>
            </div>
          </div>
        </div>



        <div className="c-content">
          <ul className="land-wrap">

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>1</span><span className='ttl'>3カ所の出入口をガード<br />安心のセキュリティ</span></p>
                <p className='txt'>歩車分離の出入口を設定。3カ所の出入口にはそれぞれに独立させたセキュリティを設けています。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_01.jpg"
                    alt=""
                  />
                </div>
                {/* <p className='c-cap black'>image photo</p> */}
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>2</span><span className='ttl'>快適なカーライフに<br />配慮した駐車場</span></p>
                <p className='txt'>駐車場を22台分※1設け、住まう方が快適なカーライフを実現できるよう配慮した駐車場計画です。<br /><br /></p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_02.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap black'>image photo</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>3</span><span className='ttl'>エントランスホール</span></p>
                <p className='txt'>エントランスホールには「ル・サンク小田原栄町」の紋をデザインした「波戸場承龍氏」のアート作品を展示（予定）しています。<br /><br /></p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_03.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap'>エントランスホール完成予想CG</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>4</span><span className='ttl'>集会室</span></p>
                <p className='txt'>「竹田純氏」がインテリアデザインを監修しました。居心地の良いサードプレイスとして思い思いのひとときをお過ごしください。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_04.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap'>集会室完成予想CG</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>5</span><span className='ttl'>駐車場入口に<br />シャッターゲート</span></p>
                <p className='txt'>駐車場の出入口にはリモコン操作で開閉ができるシャッターゲートを設置。大切な愛車を守ります。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_05.jpg"
                    alt=""
                  />
                </div>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>6</span><span className='ttl'>平置式駐車場を確保</span></p>
                <p className='txt'>車椅子の方でも車の乗り降りがしやすい平置き駐車場を1台分ご用意いたしました。<br /><br /></p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_06.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap'>image photo</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>7</span><span className='ttl'>景観も安全性にも<br />考慮した無電柱化</span></p>
                <p className='txt'>敷地の南と東側道路を無電柱化。良好な景観形成・通行空間の安全性・災害時の電柱倒壊等に配慮しています。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_07.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap'>image photo</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>8</span><span className='ttl'>駐輪場入口に<br />サイクルゲート</span></p>
                <p className='txt'>オートロック解錠のドアを設けた「サイクルゲート」をご用意。自動車の出入口を分け、日々の安全性に配慮しています。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_08.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap'>image photo</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>9</span><span className='ttl'>開放感あふれる<br />全邸南向き</span></p>
                <p className='txt'>住戸は陽当たりの良い南向き。上層階からは雄大な相模湾の眺望もお愉しみいただけます。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_09.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap black'>image photo</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>10</span><span className='ttl'>宅配ボックス<br />（メールボックス<br className='_sp' />一体型）</span></p>
                <p className='txt'>省スペース性とデザイン性を重視したメールボックス一体型タイプ。在宅・不在を問わず届いた荷物を受け取ることができます。<br /><br /></p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_10.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap black'>image photo</p>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>11</span><span className='ttl'>高圧一括<br />受電システム</span></p>
                <p className='txt'>NextPowerがマンション全体の電気を高圧電力で一括購入し、マンション内の設備にて低圧電力に変圧して各家庭と共用部に配電するサービスです。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_11.png"
                    alt=""
                  />
                </div>
              </div>
            </li>

            <li className='land-list'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>12</span><span className='ttl'>防災倉庫</span></p>
                <p className='txt'>火災や地震、その他、万が一の非常事態を考慮して、災害時に役立つ工具や防災用具などの非常用グッズを備えた防災倉庫を設置しました。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_12.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap'>image photo</p>
              </div>
            </li>

            <li className='land-list list-13'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>13</span><span className='ttl'>近づくだけでオートロックを<br className='_pc' />解錠できる「Tebra」キー</span></p>
                <p className='txt'>エントランス入口とサイクルゲートは近づくだけでオートロックを解錠できる「Tebra」キーを採用しています。<span>※Tebraキーの動作距離は作動範囲があり、<br />設置環境により変動します。</span></p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_13.jpg"
                    alt=""
                  />
                </div>
                <p className='c-cap black'>参考写真</p>
              </div>
            </li>

            <li className='land-list list-14'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>14</span><span className='ttl'>Wi-Fi対応<br />（共用部の一部）</span></p>
                <p className='txt'>1階の集会室はWi-Fiに対応しているので、お手持ちのデバイスでインターネットのご利用が可能です。</p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_14.png"
                    alt=""
                  />
                </div>
              </div>
            </li>

            <li className='land-list list-15'>
              <div className="land-txt-box">
                <p className='ttl-box'><span className='nmbr'>15</span><span className='ttl'>24時間利用可能<br />ゴミ置場</span></p>
                <p className='txt'>都合の良い時間にいつでもゴミを出せるので大変便利。室内にゴミを置かずに済むので衛生的です。<span>※ゴミ出しの際には、分別が必要です。管理規約に準じます。</span></p>
              </div>
              <div className="img-box">
                <div className="img layout">
                  <StaticImage
                    src="../assets/images/landplan/land_15.png"
                    alt=""
                  />
                </div>
              </div>
            </li>

          </ul>

        </div>
      </section>


      <section className='sec-green'>
        <div className="c-content">

          <div className="green-wrap">
            <div className="green-txt-box">
              <p className='ttl comFeed'>GREEN PLAN</p>
              <p className='lead'>住まう方・近隣の方の<br />コミュニケーションを育む<br className='_sp' />自主管理広場</p>
              <div className="txt comFeed">敷地の南東角地にどなたでもご利用いただける、<br />ベンチを備え植栽で彩った<br className='_sp' />オープンスペースをデザインしました。</div>
            </div>

            <div className="img-box">
              <div className="img green01 comFeed _pc">
                <StaticImage
                  src="../assets/images/landplan/green.png"
                  alt=""
                />
              </div>

              <div className="img green01 comFeed _sp">
                <StaticImage
                  src="../assets/images/landplan/green_sp.png"
                  alt=""
                />
              </div>

              <div className="img green02 comFeed">
                <StaticImage
                  src="../assets/images/landplan/green_02.jpg"
                  alt=""
                />
              </div>
              <p className='c-cap'>自主管理広場完成予想イラスト</p>
            </div>
          </div>

        </div>

        <div className="c-content">
          <div className="com-note-wrap">
            <p className='c-note'>※1:機械式21台、平置式1台。この他に店舗（1）用駐車場が1台あります。<br />※掲載の敷地配置・自主管理広場完成予想イラストは図面を基に描き起こしたもので実際とは異なります。植栽は特定の季節やご入居時の状態を想定して描かれたものではありません。<br />※掲載の完成予想CGは、計画段階の図面を基に描いたもので、外観・外構・植栽・仕上げ等は実際とは異なる場合があります。家具・照明器具・調度品等は、実際に設置されるものと異なる場合があります。外観形状の細部や設備機器等は表現されておりません。またタイルや各種部材の質感や色等は実際とは異なります。今後、行政指導及び施工上の理由等のため計画が変更となる場合があります。  <br />※植栽は育成に必要な環境の下で、竣工から一定期間を経た状態のものを想定して描いており、特定の季節を表したものではありません。 <br />※「image photo」の表記がある写真は全てイメージです。<br />※掲載の参考写真はメーカーによる写真での実際の仕様とは異なる場合がございます。<br />※掲載の植裁写真は全てイメージです。</p>
          </div>
        </div>
      </section>



      {/* CONTENTS */}
    </main>
  )
}

export default LandplanPage
